.new-user-create {
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  width: 100%;
}

.container {
  background-color: #f3f3f3;
  width: 100%;
  max-width: 1920px;
  padding: 0 20px;
}

.header {
  background-color: #ffffff;
  border-bottom: 1px solid #d0d0e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
}

.back-button {
  background-color: #ffffff;
  box-shadow: 0px 0px 10.7px #0000001a;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.page-title {
  font-size: 28px;
  font-weight: 500;
  color: #232327;
}

.header-right {
  display: flex;
  align-items: center;
}

.notification {
  position: relative;
  margin-right: 20px;
}

.bell {
  width: 18px;
  height: 18px;
}

.notification-count {
  background-color: #f05252;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  color: #232327;
}

.user-role {
  font-size: 14px;
  color: #797986;
}

.dropdown-icon {
  width: 16px;
  height: 16px;
}

.form-container {
  background-color: #ffffff;
  border-radius: 17px;
  padding: 40px;
  width: 100%;
  max-width: 1572px;
}

.form-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 40px;
}

.user-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 50px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 16px;
  font-weight: 500;
  color: #797986;
  margin-bottom: 10px;
}

input {
  padding: 10px;
  border: 1px solid #d0d0e1;
  border-radius: 5px;
  font-size: 16px;
  color: #232327;
}

.select-wrapper {
  position: relative;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #d0d0e1;
  border-radius: 5px;
  font-size: 16px;
  color: #232327;
  appearance: none;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.country-code {
  font-size: 16px;
  color: #232327;
}

.flag {
  width: 18px;
  height: 18px;
}

.calendar-icon,
.eye-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}

.btn-back {
  background-color: #ffffff;
  border: 1.5px solid #545560;
  border-radius: 5px;
  padding: 14px 74px;
  font-size: 18px;
  font-weight: 600;
  color: #545560;
  cursor: pointer;
}

.btn-create {
  background-color: #1a1a3f;
  border-radius: 5px;
  padding: 14px 74px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
