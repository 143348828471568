@import url("./assets/fonts/index.css");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* min-height: 100vh; */
}

input:focus-visible {
  border-color: #1B154B !important;
  box-shadow: none !important;
}

textarea:focus-visible {
  box-shadow: none !important;
}

textarea::-webkit-input-placeholder {
  color: #757575;
}

::-webkit-scrollbar {
  height: 0px;
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #d0d0e1;
  border-radius: 5px;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ballpark-swiper-prev,
.ballpark-swiper-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  background-color: rgba(95, 95, 95, 0.767);
  color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-40%);
}

.ballpark-swiper-prev {
  left: -18px;
}

.ballpark-swiper-next {
  right: -18px;
}

.ballpark-swiper-prev.swiper-button-disabled,
.ballpark-swiper-next.swiper-button-disabled {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .ballpark-swiper-prev,
  .ballpark-swiper-next {
    padding: 5px;
    font-size: 20px;
    border: 1px solid white;
  }
  .ballpark-swiper-prev {
    left: -10px;
  }

  .ballpark-swiper-next {
    right: -10px;
  }
}
.ballparks-slider .mySwiper > .swiper-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
}
